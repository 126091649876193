import { buildCollection, buildProperty } from "@firecms/core";

export enum MemberRole {
  admin = "admin",
  editor = "editor",
  customizer = "customizer",
}
export type Member = {
  name: string;
  email: string;
  role: MemberRole;
}

export const teamMembersCollection = buildCollection<Member>({
  name: "Team",
  singularName: "Member",
  path: "team",
  exportable: false,
  id: "team",
  icon: "group",
  group: "Admin",
  permissions: ({ authController, user }) => ({
    read: true,
    edit: authController.extra?.role === MemberRole.admin,
    create: authController.extra?.role === MemberRole.admin,
    delete: authController.extra?.role === MemberRole.admin
  }),
  properties: {
    name: buildProperty({
      name: "Name",
      validation: { required: true },
      dataType: "string"
    }),
    email: buildProperty({
      name: "Email",
      validation: { required: false },
      dataType: "string"
    }),
    role: buildProperty({
      name: "Role",
      validation: { required: false },
      dataType: "string",
      enumValues: {
        admin: "Admin",
        editor: "Editor",
        customizer: "Customizer"
      }
    })
  }
})
