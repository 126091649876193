import { buildCollection } from "@firecms/core";
import { Customization } from "./chatbot/chatbots";
import { chatbotCustomizationWithNoDefaultsProperty } from "./chatbot/chatbot_customization_properties";
import { MemberRole } from "./team";

export type DomainBasedCustomization = {
  domain: string;
  customization: Customization;
}

export const domainBasedCustomizationCollection = buildCollection<DomainBasedCustomization>({
  id: "domain_customization",
  name: "Domain Customization",
  path: "domain_customization",
  group: "Main",
  icon: "format_paint",
  textSearchEnabled: true,
  permissions: ({ authController, user }) => ({
    read: true,
    edit: authController.extra?.role === MemberRole.admin,
    create: authController.extra?.role === MemberRole.admin,
    delete: authController.extra?.role === MemberRole.admin
  }),
  properties: {
    domain: {
      name: "Domain",
      description: "The domain of the website where the chatbot will be embedded, for example: getdeardoc.com",
      validation: { unique: true, required: true, trim: true, matches: /^[A-Za-z0-9-.*]{1,253}\.[A-Za-z]{2,9}$/gm, matchesMessage: "Invalid format. You only need to provide the domain, for example: getdeardoc.com or chat.getdeardoc.com" },
      dataType: "string"
    },
    customization: chatbotCustomizationWithNoDefaultsProperty
  }
});
