import { AdditionalFieldDelegate, buildCollection, buildProperty } from "@firecms/core";
import { Markdown } from "@firecms/ui";

interface DisplayInfo {
  width: number;
  height: number;
  availableWidth: number;
  availableHeight: number;
  colorDepth: number;
  pixelDepth: number;
  orientationAngle: number;
}

interface Geolocation {
  country: string;
  region: string;
  city: string;
  coordinates?: number[],
}

export interface ClientMetadata {
  ip: string;
  userId: string;
  language: string;
  languages: string[];
  geolocation: Geolocation;
  displayInfo: DisplayInfo;
  deviceType: "mobile" | "tablet" | "computer";
  userAgent: string;
  referral: string;
}

export interface MessageMetaData {
  fulfilledProperties?: string[];
  generativeModelName?: string;
}

export interface Message {
  createdAt: Date;
  sender: "client" | "chatbot";
  text: string;
  suggestions: string[];
  inputType?: "text" | "name" | "email" | "tel";
  metadata: MessageMetaData;
}

export interface Conversation {
  clientMetadata: ClientMetadata;
  createdAt: Date;
  chatOpenedAt?: Date;
  endedAt?: Date;
  messages: Message[];
  data: { [key: string]: any };
  conversationCompleted: boolean;
}

const transcriptField: AdditionalFieldDelegate<Conversation> = {
  key: "transcript",
  name: "Transcript",
  Builder: ({ entity }) => {
    return <Markdown source={
      (entity.values.messages ?? []).map((message) => {
        return `${["ai", "chatbot"].includes(message.sender) ? "**ai**" : "**customer**"}: ${message.text}`;
      }).join("\n\n")
    }></Markdown>
  }
}

export const conversationsCollection = buildCollection<Conversation>({
  name: "Conversations",
  singularName: "Conversation",
  textSearchEnabled: false,
  path: "conversations",
  id: "conversations",
  icon: "message",
  initialSort: ["createdAt", "desc"],
  editable: false,
  hideIdFromForm: true,
  hideIdFromCollection: true,
  group: "Main",
  permissions: ({ authController, user }) => ({
    read: true,
    edit: false,
    create: false,
    delete: false
  }),
  properties: {
    clientMetadata: buildProperty<ClientMetadata>({
      name: "Client Metadata",
      dataType: "map",
      readOnly: true,
      validation: {
        required: true
      },
      properties: {
        ip: buildProperty({
          name: "IP",
          dataType: "string",
          validation: {
            required: false
          }
        }),
        userId: buildProperty({
          name: "User Id",
          dataType: "string",
          validation: {
            required: false
          }
        }),
        language: buildProperty({
          name: "Language",
          dataType: "string",
          validation: {
            required: false
          }
        }),
        languages: buildProperty({
          name: "Languages",
          dataType: "array",
          of: buildProperty({
            name: "Language",
            dataType: "string"
          }),
          validation: {
            required: false
          }
        }),
        geolocation: buildProperty<Geolocation>({
          name: "Geolocation",
          expanded: false,
          dataType: "map",
          properties: {
            country: buildProperty({
              name: "Country",
              dataType: "string",
              validation: {
                required: false
              }
            }),
            region: buildProperty({
              name: "Region",
              dataType: "string",
              validation: {
                required: false
              }
            }),
            city: buildProperty({
              name: "City",
              dataType: "string",
              validation: {
                required: false
              }
            }),
            coordinates: buildProperty({
              name: "Coordinates",
              dataType: "array",
              of: buildProperty({
                name: "Coordinate",
                dataType: "number"
              }),
              validation: {
                required: false
              }
            })
          }
        }),
        displayInfo: buildProperty<DisplayInfo>({
          name: "Display Info",
          dataType: "map",
          expanded: false,
          properties: {
            width: buildProperty({
              name: "Width",
              dataType: "number",
              validation: {
                required: false
              }
            }),
            height: buildProperty({
              name: "Height",
              dataType: "number",
              validation: {
                required: false
              }
            }),
            availableWidth: buildProperty({
              name: "Available Width",
              dataType: "number",
              validation: {
                required: false
              }
            }),
            availableHeight: buildProperty({
              name: "Available Height",
              dataType: "number",
              validation: {
                required: false
              }
            }),
            colorDepth: buildProperty({
              name: "Color Depth",
              dataType: "number",
              validation: {
                required: false
              }
            }),
            pixelDepth: buildProperty({
              name: "Pixel Depth",
              dataType: "number",
              validation: {
                required: false
              }
            }),
            orientationAngle: buildProperty({
              name: "Orientation Angle",
              dataType: "number",
              validation: {
                required: false
              }
            })
          },
          validation: {
            required: false
          }
        }),
        deviceType: buildProperty({
          name: "Device Type",
          dataType: "string",
          config: {
            enumValues: [
              {
                value: "mobile",
                label: "Mobile"
              },
              {
                value: "tablet",
                label: "Tablet"
              },
              {
                value: "computer",
                label: "Computer"
              }
            ]
          },
          validation: {
            required: false
          }
        }),
        userAgent: buildProperty({
          name: "User Agent",
          dataType: "string",
          validation: {
            required: false
          }
        }),
        referral: buildProperty({
          name: "Referral",
          dataType: "string",
          url: true,
          validation: {
            required: false
          }
        })
      }
    }),
    createdAt: buildProperty({
      name: "Created At",
      dataType: "date",
      mode: "date_time",
      readOnly: true,
      validation: {
        required: true
      }
    }),
    chatOpenedAt: buildProperty({
      name: "Chat Opened At",
      dataType: "date",
      mode: "date_time",
      readOnly: true,
      validation: {
        required: false
      }
    }),
    endedAt: buildProperty({
      name: "Conversation Ended At",
      dataType: "date",
      mode: "date_time",
      readOnly: true,
      validation: {
        required: false
      }
    }),
    messages: buildProperty({
      name: "Messages",
      dataType: "array",
      expanded: false,
      of: buildProperty({
        name: "Message",
        dataType: "map",
        properties: {
          createdAt: buildProperty({
            name: "Created At",
            dataType: "date",
            mode: "date_time",
            readOnly: true,
            validation: {
              required: true
            }
          }),
          sender: buildProperty({
            name: "Sender",
            dataType: "string",
            readOnly: true,
            config: {
              enumValues: [
                {
                  value: "client",
                  label: "Client"
                },
                {
                  value: "chatbot",
                  label: "Chatbot"
                }
              ]
            },
            validation: {
              required: true
            }
          }),
          text: buildProperty({
            name: "Text",
            dataType: "string",
            readOnly: true,
            validation: {
              required: true
            }
          }),
          suggestions: buildProperty({
            name: "Suggestions",
            dataType: "array",
            of: buildProperty({
              name: "Suggestion",
              dataType: "string"
            }),
            readOnly: true,
            validation: {
              required: true
            }
          }),
          metadata: buildProperty<MessageMetaData>({
            name: "Metadata",
            dataType: "map",
            expanded: false,
            properties: {
              fulfilledProperties: buildProperty({
                name: "Fulfilled Properties",
                dataType: "array",
                of: buildProperty({
                  name: "Fulfilled Property",
                  dataType: "string"
                }),
                validation: {
                  required: false
                }
              }),
              generativeModelName: buildProperty({
                name: "Generative Model Name",
                dataType: "string",
                validation: {
                  required: false
                }
              })
            },
            validation: {
              required: false
            }
          })
        }
      }),
      validation: {
        required: true
      }
    }),
    conversationCompleted: buildProperty({
      name: "Conversation Completed",
      dataType: "boolean",
      validation: {
        required: false
      },
      readOnly: true
    }),
    data: buildProperty({
      name: "Data",
      keyValue: true,
      dataType: "map",
      validation: {
        required: true
      },
      readOnly: true
    })
  },
  additionalFields: [transcriptField]
})
